<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">我的订单</a>
        </div>
      </div>
      <div class="my_order m1440">
        <div class="nav_box flex-box">
          <div :class="navIndex == 0 ? 'active' : ''" @click="switch_tab(0)">
            全部订单
          </div>
          <div :class="navIndex == 1 ? 'active' : ''" @click="switch_tab(1)">待付款</div>
          <div :class="navIndex == 2 ? 'active' : ''" @click="switch_tab(2)">已付款</div>
          <div :class="navIndex == 3 ? 'active' : ''" @click="switch_tab(3)">已完成</div>
          <div :class="navIndex == 4 ? 'active' : ''" @click="switch_tab(4)">取消</div>
        </div>
        <div class="status_box flex-box fs18">
          <div>订单详情</div>
          <div>金额</div>
          <div>状态</div>
          <div>操作</div>
        </div>
        <div v-if="order_list.length == 0">
          <div class="no_data">
            <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
            <span>暂无数据</span>
          </div>
        </div>
        <ul class="order_list" v-else>
          <li class="mb30" v-for="(v, k) in order_list" :key="k" @click.stop="go_detail(v.id)">
            <div class="flex-box num_date">
              <div class="col9">订单号：</div>
              <div>{{ v.tradeNo }}</div>
              <div class="pl60 col9">{{ v.createTime }}</div>
            </div>
            <div class="flex-box" v-for="(vg, kg) in v.product" :key="kg">
              <div class="goods_info flex-box">
                <img :src="oss + vg.thumbnail" class="img_about mr20" alt="" />
                <div class="title">
                  <div class="fs20 fwb">{{ vg.productName }}</div>
                  <div class="fs20 num">x{{ v.totalCount_text?v.totalCount_text:v.totalCount }}</div>
                </div>
              </div>
              <div class="fifteen fs28 red fwb">
                <span class="fs16">¥</span>{{vg.price_text?vg.price_text:vg.price }}
                <span class="col9 fs16">{{vg.unit?vg.unit:'' }}</span>
              </div>
              <div class="fifteen fs20">
                <div class="pb15 normal" v-if="v.status == 'UNPAID'">待付款</div>
                <!-- mao modify 20240520 -->
                <div class="pb15 col9" v-if="v.status == 'PAID' || v.status == 'CHECK' || v.status == 'WAIT_UPLOAD'">
                  已付款
                </div>
                <!-- end -->
                <div class="pb15 col9" v-if="v.status == 'COMPLETE'">已完成</div>
                <div class="pb15 normal" v-if="v.status == 'CANCEL'">已取消</div>
                <div class="pb15 normal" v-if="v.status == 'FAILED'">未通过</div>
                <a href="javascript:;">订单详情</a>
              </div>
              <div class="fifteen">
                <div class="already_pay fs22" v-if="v.status == 'UNPAID'" @click.stop="payMoneyShow(k)">我已付款</div>
                <div class="fs20 cancel_order" v-if="v.status == 'UNPAID'" @click.stop="change_status(1, k)">取消订单</div>
                <div class="del_order fs22" v-if="v.status == 'CANCEL'" @click.stop="change_status(2, k)">删除订单</div>
                <div class="fs20 cancel_order" v-if="v.status == 'PAID'">
                  待审核
                </div>
                <div class="fs20 cancel_order" v-if="v.status == 'CHECK'">
                  信息核对中
                </div>
                <!-- invoiceStatus: "0" contractStatus: "0"  -->
                <!-- mao modify 20240417 -->
                <!-- <div class="btns" v-if="v.status == 'COMPLETE'"> -->
                <div class="btns" v-if="v.status == 'COMPLETE' && (v.invoiceStatus == '0'|| v.invoiceStatus == '1')">   
                  <span v-if="v.contractStatus == '0'" @click.stop="createShow(k)">生成合同</span>
                  <span v-if="v.contractStatus == '1'" @click.stop="jump_type(2,k)">查看合同</span>
                </div>
                <div class="btns" v-if="v.status == 'COMPLETE' && v.contractStatus == '1'">
                  <span @click.stop="jump_type(4, k)">下载合同</span>
                </div>
                <div class="btns" v-if="v.status == 'COMPLETE' && (v.invoiceStatus == '0'|| v.invoiceStatus == '1')">
                  <span v-if="v.invoiceStatus == '0'" @click.stop="jump_type(1, k)">申请发票</span>
                  <span v-if="v.invoiceStatus == '1'">开票中</span>
                </div>
                <div class="btns" v-if="v.status == 'COMPLETE' && v.invoiceStatus == '2' && v.tradeInvoice[0].materialType == 'PAPER'">
                    <span @click.stop="jump_type(3, k)">发票物流</span>
                </div>
                <div class="btns" v-if="v.status == 'COMPLETE' && v.invoiceStatus == '2' && v.tradeInvoice[0].materialType == 'ELECTRIC'">
                    <span @click.stop="jump_type(6, k)">查看pdf发票</span>
                </div>
                <div class="btns" v-if="v.status == 'COMPLETE' && v.invoiceStatus == '2' && v.tradeInvoice[0].materialType == 'ELECTRIC'">
                    <span  @click.stop="jump_type(7, k)">查看ofd发票</span>
                </div>
              </div>
            </div>
          </li>
               <!-- <div class="nomore" v-if="isShowNavBarLoading">没有更多了~</div> -->
        <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
        <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>

        </ul>
       
      </div>
    </div>
    <Popup :visible="voucher" :confirmText="false" :cancleText="false" @close="voucherPop">
      <div class="voucher_pop">
        <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="voucherPop" alt="">
        <div class="title">上传付款凭证</div>
        <div class="ptb10 plr15">
          <div class="fs16 fwb pb10">收款账号</div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">开户行</div>
            <div class="">{{ bankCard.bankName }}</div>
          </div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">账号</div>
            <div class="">{{ bankCard.bankAccount }}</div>
          </div>
          <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">全称</div>
            <div class="">{{ bankCard.cardOwner }}</div>
          </div>
          <!-- <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">电话</div>
            <div class="">{{ bankCard.ownerTel }}</div>
          </div> -->
        </div>
        <div class="fs13 col6 ptb10 plr15">上传支付凭证</div>

        <div class="flex-box flex-start">
          <div class="up_box_list" v-for="(item, index) in images" :key="index">
            <img :src="oss + item" class="up_pic" alt="" />
            <img src="../assets/static/icon/icon_close.png" @click.stop="del_img(index)" class="close" alt="">
          </div>
          <div class="up_box">
            <input multiple type="file" @change="upload_img" value=""
              accept="image/png,image/jpeg,image/gif,image/jpg" />
          </div>
        </div>
        <div class="up_voucher" @click="payMoney">确认付款</div>
      </div>
    </Popup>

    <Popup :visible="popup2" :confirmText="false" :cancleText="false" @close="closePop">
          <div class="sure_pop">
            <img
              src="../assets/static/icon/icon_close.png"
              class="icon_close"
              @click="closePop()"
              alt=""
            />
            <img src="../assets/static/icon/icon_contract.png" class="icon_contract" alt="" />
            <div class="fs16 tc pb40">是否确定生成合同？</div>
            <div class="sure_cancel flex-box flex-center fs18">
              <div @click="closePop">取消</div>
              <div v-if="is_first==false" @click="create">确定</div>
              <div v-else>生成中</div>
            </div>
          </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
import axios from "axios";
import cookie from "vue-cookies";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      navIndex: 0, //tab
      oss: "",
      popup: false,
      popup2: false, //申请合同弹窗
      voucher: false,
      bankCard: {},//银行信息
      choose_index: '',//选择的订单
      order_list: [],
      pageSize: 15, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: true, // 是否加载完
      images: [],
      invoices_list:[],
      s_index:-1, //生成合同下标
      is_first:false
    };
  },
  // 页面渲染完执行
  created() {
    let _that = this;
    _that.oss = _that.global.uploadOss;
    // 获取列表
    _that.get_user_info();
    _that.get_order_list();
    _that.invoices()
  },
  methods: {

    //申请跳转发票和合同
    jump_type(type, index) {
      let that = this;
      let list = that.order_list
      if (type == 1) {
        //申请发票
        let in_list = that.invoices_list
      if(in_list.length <=0){
        that.$message.error("请先完善发票信息");
        setTimeout(() => {
          that.$router.push("/invoices_maintain");
        }, 800);
      }else{
         var id = escape('环保桥'+ list[index].id)
         var tradeNo = escape('环保桥'+ list[index].tradeNo)
         that.$router.push("/invoices_apply?id=" + id + '&no=' + tradeNo);
      }
      }else if(type ==2){
          // 预览合同
        let tradeId = list[index].id 
        that.$api.browseContractPage({tradeId:tradeId}).then((res) => {
        if (res.resCode == 0) {
          window.open(res.root.pageUrl, "_blank");
        } else {
          that.$message.error(res.resMsg);
        }
      });
      }else if(type ==4){
           // 下载合同
           that.$api.downloadContractPDF({ tradeId: list[index].id  }).then((res) => {
          if (res.resCode == 0) {
            var fileName = res.root.root.contractPdf
            var url = that.oss + fileName
            window.open(url, "_blank");
          } else {
            that.$message.error(res.resMsg);
          }
        });
      }else{
        let data = list[index].tradeInvoice[0]
        if(type ==3){
         //查看物流
        that.$router.push("/invoices_logistics?code=" + escape('环保桥' + list[index].id));
        }else if(type ==6 || type ==7){
          let url = data.invoiceFile
        if(url){
          var pdf = ''
          var ofd = ''
						let url_arr =  url.split(",")
						  for (let i = 0; i < url_arr.length; i++) {
                let hz = url_arr[i].substr(url_arr[i].lastIndexOf('.'));
                 if(hz=='.pdf'){
                  pdf = that.oss +url_arr[i] 
                 }else if(hz=='.ofd'){
                  ofd = that.oss +url_arr[i] 
                 }
						  }
              if(type==6){
                window.open(pdf, "_blank");
                that.downFile(pdf)
                return;
              }else if(type==7){
                // window.open(ofd, "_blank");
                that.downFile(ofd)
                return;
              }
					} 
        }
      //    //查看发票
      // let url = list.invoicePic
      //   if(url){
			// 			let url_arr =  url.split(",")
			// 			 let new_url = []
			// 			  for (let i = 0; i < url_arr.length; i++) {
			// 				   new_url.push(this.oss +url_arr[i]) 
			// 			  }
      //       this.$hevueImgPreview({
      //           multiple: true, // 开启多图预览模式
      //           nowImgIndex: 0, // 多图预览，默认展示第二张图片
      //           imgList:new_url, // 需要预览的多图数组
      //       })
			// 		}
      }
    },
  //直接下载pdf
  downFile(url) {
      const elink = document.createElement("a");
      elink.style.display = "none";
      elink.href = url + '?response-content-type=application/octet-stream';
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    },

    closePop() {
      this.s_index = -1;
      this.popup2 = !this.popup2;
      this.is_first = false
    },

    createShow(index){
      this.s_index = index;
      this.popup2 = !this.popup2;
    },
     
    create(){
      let list = this.order_list
      let index = this.s_index
      if(index <0){
        this.$message.error('请选择要生成的合同订单');
        return;
      }
        //判断用户是否完善了公司信息
        let userinfo = cookie.get("huanbaoqiao_userinfo");
      if(!userinfo.companyName || !userinfo.companyNameEn ||!userinfo.businessLicense 
         ||!userinfo.companyAddress ||!userinfo.contactor || !userinfo.contactorTel ||!userinfo.contactorEmail ){
        this.$message.error('请先完善公司信息');
        setTimeout(function(){
          this.$router.push("/info")
        },800)
        return;
      }
      if (list[index].contractPdf != null && list[index].contractPdf != '') {
        this.$message.error('当前订单已生成合同');
        return;
      }
      this.is_first = true
      this.$api.createDraftContract({tradeId:list[index].id}).then((res) => {
        if (res.resCode == 0) {
          this.$message.success(res.resMsg);
          list[index].contractStatus = 1
          this.order_list = list
          this.s_index = -1;
          this.popup2 = false;
          this.is_first = false
        } else {
          this.$message.error(res.resMsg);
          this.s_index = -1;
          this.popup2 = false;
          this.is_first = false
        }
      }).catch((err) => {
        this.is_first = false
				});
    },
    go_detail(id) {
      this.$router.push("/order_detail?code=" + escape('环保桥'+ id));
    },

    //删除或取消
    change_status(type, index) {
      let that = this
      let list = this.order_list
      let data = {
        id: list[index].id
      }
      if (type == 1) {
        var title = '是否取消订单？'
      } else {
        var title = '是否删除订单？'
      }
      MessageBox.confirm(title, "温馨提示", { type: "warning" })
        .then((rec) => {
          // 判断 是否是 确认
          if (rec == "confirm") {
            //取消
            if (type == 1) {
              that.$api.apiCancelOrder({tradeId:list[index].id})
                .then((res) => {
                  if (res.resCode == 0) {
                    that.$message.success("取消成功");
                    let tab = that.navIndex
                    if (tab == 0) {
                      list[index].status = 'CANCEL'
                    } else {
                      list.splice(index, 1)
                    }
                    that.order_list = list
                  } else {
                    that.$message.error(res.resMsg);
                  }
                });
            } else {
              //删除
              let userinfo = cookie.get("huanbaoqiao_userinfo");
              data.updaterId = userinfo.id
              that.$api.apiOrderDelete(data)
                .then((res) => {
                  if (res.resCode == 0) {
                    that.$message.success("删除成功");
                    list.splice(index, 1)
                    that.order_list = list
                  } else {
                    that.$message.error(res.resMsg);
                  }
                });
            }
          } else {
            // 取消操作
          }
        })
    },


    //上传凭证
    upload_img(e) {
      let that = this
      let loading = that.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.5)'
      })
      let formdata = new FormData();
      Array.from(e.target.files).map((item) => {
        formdata.append("file", item); //将每一个文件图片都加进formdata
      });
      axios.post(axios.defaults.baseURL + "v1/cdn/ali/upload", formdata).then((res) => {
        if (res.data.resCode == 0) {
          loading.close();
          let images = that.images;
          let data = res.data.root;
          images.push(data);
          that.images = images;
        } else {
          loading.close();
          that.$message.error(res.data.resMsg);
        }
      });
    },
    //删除图片
    del_img(index) {
      let list = this.images
      list.splice(index, 1)
      this.images = list
    },
    //确认付款
    payMoney() {
      let that = this;
      let images = that.images;
      if (images.length <= 0) {
        that.$message.error("请上传支付凭证");
        return;
      }
       let list = that.order_list
        let c_index = that.choose_index
        that.$api.apiUploadProof({ id: list[c_index].id, purchaseProof: images.join(",") })
          .then((res) => {
            that.voucher = false;
            if (res.resCode == 0) {
              that.$message.success("提交成功,待后台审核");
              setTimeout(function () {
                let tabIndex = that.navIndex
                if (tabIndex == 0) {
                  list[c_index].status = 'CHECK'
                } else {
                  list.splice(c_index, 1)
                }
                that.order_list = list
                that.images = []
                that.choose_index = ''
                that.order_id = 0
                that.bankCard = {}
                that.fileList1 = []
                that.voucher = false
              }, 800);
            } else {
              that.$message.error(res.resMsg);
            }
          }).catch((err) => {
          that.voucher = false;
        });
    },
    voucherPop() {
      this.voucher = false;
    },
    payMoneyShow(ik) {
      var _that = this;
      let order_list = _that.order_list;
      _that.choose_index = ik;
      _that.bankCard = order_list[ik].bankCard[0];
      _that.voucher = !_that.voucher;
    },
    // 切换状态
    switch_tab(ik) {
      let _that = this;
      _that.navIndex = ik;
      _that.pageNum = 1;
      _that.get_order_list();
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_order_list();
    },
    // 获取列表
    get_order_list() {
      var that = this;
      let page = that.pageNum;
      let search_data = {
        page: page,
        limit: that.pageSize,
        pkg_product_father: "com.dazz.business.model.product.Product",
        pkg_tradeInvoice:'com.dazz.business.model.tradeInvoice.TradeInvoice',
        deleted:'0'
      };
      let navIndex = that.navIndex;
      if (navIndex == 1) {
        search_data.status = "UNPAID";
      } else if (navIndex == 2) {
        // mao modify 20240520
        search_data.status = "PAID,CHECK,WAIT_UPLOAD";
        // end
      } else if (navIndex == 3) {
        search_data.status = "COMPLETE";
      } else if (navIndex == 4) {
        search_data.status = "CANCEL";
      }
      this.$api.myOrderList(search_data).then((data_res) => {
        if (data_res.resCode == 0) {
          var totalCount = data_res.totalCount;
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
            that.total = totalCount;
            that.order_list = data_res.root;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            let list = data_res.root
             for(var i=0;i<list.length;i++){
                for(var k=0;k<list[i].product.length;k++){
                  list[i].product[k].price_text = that.global.addCommas(list[i].product[k].price)
                }
                list[i].totalCount_text = that.global.addCommas(list[i].totalCount)
             }
            that.total = totalCount;
            that.order_list = list;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.$message.error(data_res.msg);
        }
      });
    },
    //获取发票信息
    invoices(){
      let that = this
      that.$api.userInvoices({}).then((res) => {
        if (res.resCode == 0) {
          that.invoices_list = res.root
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });
    },

    get_user_info() {
      let that = this;
      let user = cookie.get("huanbaoqiao_userinfo");
      let token = cookie.get("huanbaoqiao_token");
      if (!user || !token) {
        this.$message.error('请先登录');
        this.$router.push("/login")
        return;
      }
      that.$api.apiUserInfo({ deviceType: '1', id: user.id ? user.id : 0}).then((res) => {
        if (res.resCode == 0) {
          if (res.root[0].avatar != null && res.root[0].avatar != '') {
            that.oss_avatar = res.root[0].avatar
          }
          cookie.set("huanbaoqiao_userinfo", res.root[0])
        }else if(res.resCode == 401){
              this.$message.error('请先登录');
            this.$router.push("/login")
            return;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

  },
};
</script>
